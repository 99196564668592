import { Resolver, useForm } from "react-hook-form"

type UserInfo = {
    employeeId: number;
    lastName: string;
}

type props = {
    setSuccess: any
    setMessage: any
    setDisplay: any
}

const resolver: Resolver<UserInfo> = async (values) => {
    return {
        values: values.lastName && values.employeeId ? values : {},
        errors: !values.lastName && !values.employeeId
            ? {
                lastName: {
                    type: 'required',
                    message: 'Last name is a required field',
                },
                employeeId: {
                    type: 'required',
                    message: 'Employee ID is a required field.'
                }
            }
            : {}
    }
}

export const ClaimForm: React.FC<props> = ({ setSuccess, setMessage, setDisplay }) => {

    const { register, handleSubmit, formState: { errors } } = useForm<UserInfo>({ resolver });

    const onSubmit = async (data: any) => {
// add azure url in the fetch
        const response = fetch( "https://amcwptokenclaimportal.azurewebsites.net/api/externalidhttptrigger", {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.status == 400 || 404) {
                    setDisplay(true)
                    setSuccess(false)
                    setMessage("Sorry, there seems to be an issue with the information entered. Please try again. If the error persists, please email WorkplaceSupport@amctheatres.com to obtain the correct information to access AMC Workplace.")
                }
                return response.json()
            })
            .then(responseBody => {
                const { code } = responseBody
                if (code) {
                    setDisplay(true)
                    setSuccess(true)
                    setMessage(code)
                }
            })
            .catch(error => {
                setDisplay(true)
                setSuccess(false)
                setMessage("Sorry, there seems to be an issue with the information entered. Please try again. If the error persists, please email WorkplaceSupport@amctheatres.com to obtain the correct information to access AMC Workplace.")
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label>
                    <b>Associate ID:</b>
                    <br />
                    (Please enter your 7-digit Associate ID)
                    <input  {...register("employeeId", { required: true })} />
                    {errors?.employeeId && <p>{errors.employeeId?.message}</p>}
                </label>
            </div>
            <div className="form-group">
                <label>
                    <b>Last Name:</b>
                    <br />
                    (Please enter your last name- case sensitive)
                    <input {...register("lastName", { required: true })} />
                    {errors?.lastName && <p>{errors?.lastName.message}</p>}
                </label>
            </div>
            <input type="submit" className="btn" value="Submit" />
        </form>
    )
}