import styles from './layout.module.css';
import logo from './amc-logo.png';

export type LayoutProps = {
    children : React.ReactNode;
}

export const Layout  = ( props : LayoutProps ) => {
    return (
        
        <div className={styles.container}>
            <img src={logo} alt="AMC Theatres logo"/>
            <br/>
            {props.children}
        </div>
    )
}